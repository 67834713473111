@import "../../../../public/assets/scss/tokens.scss";

.sidebar {
  &__logo {
    padding: calc($spacing-size-xxl * 2) $spacing-size-xxl calc($spacing-size-xl * 2) $spacing-size-xxl;
    font-weight: $font-weight-sm;
    font-size: $font-size-xxl;
    line-height: $spacing-size-xxxl;
    letter-spacing: -0.02em;
    color: $primary-white;
  }

  &__divider {
    border: $spacing-size-xxxs solid $medium-gray;
    opacity: 0.24;
    margin: $spacing-size-none;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
  }

  &__navigation-list {
    margin: $spacing-size-none;
    padding: $spacing-size-xxl $spacing-size-md;
    list-style: none;
  }

  &__navigation-list-item {
    margin-bottom: $spacing-size-md;
  }

  &__navigation-link {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    font-weight: $font-weight-xs;
    font-size: $font-size-xs;
    cursor: pointer;
    color: $primary-white;
    text-decoration: none;
    background: transparent;
    padding: $spacing-size-lg $spacing-size-xl $spacing-size-lg $spacing-size-md;
    transition: all 0.5s ease;

    &--active,
    &:hover {
      background: rgba(255, 255, 255, 0.03);
      box-shadow: $spacing-size-none $spacing-size-none $spacing-size-none $spacing-size-xxxs rgba(245, 239, 235, 0.16);
      border-radius: $border-radius-lg;
      font-weight: $font-weight-md;
    }
  }

  &__navigation-icon {
    margin-right: $spacing-size-lg;
    font-size: $spacing-size-xl;
    &--expand {
      margin-right: $spacing-size-none;
      margin-left: $spacing-size-xs;
    }
  }

  &__submenu {
    list-style-type: disc;
    color: $primary-white;
  }

  &__submenu-item {
    margin-left: $spacing-size-xxxl;
    margin-right: $spacing-size-xxl;
    margin-top: $spacing-size-xs;
  }

  &__submenu-item-link {
    padding: $spacing-size-sm $spacing-size-lg;
  }
}
