@import "../../../public/assets/scss/tokens.scss";

.page {
  display: flex;
  flex-direction: row;
  background-color: $light;

  &__sidebar {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 18rem;
    overflow: auto;
    background-color: $primary;
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    width: calc(100% - 18rem);
    overflow: auto;
    padding: $spacing-size-xxxl;
    position: relative;
  }
}
