@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-Black.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-Thin.ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("CeraPro-ThinItalic.ttf");
  font-weight: 100;
  font-style: italic;
}
